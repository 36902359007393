import "./App.css";
import MainView from "./MastersPool/views/Live/MainView";
import Signup from "./MastersPool/views/Signup/Signup";
import Container from "react-bootstrap/Container";

function App() {
  return (
    <Container fluid>
      {/* <MainView /> */}
      <Signup />
    </Container>
  );
}

export default App;
