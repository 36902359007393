import React, { useCallback, useEffect, useState } from "react";
import "../../styles/signup.scss";
import { getAllGolfers, setUpUser } from "../../queries";
import Form from "react-bootstrap/Form";
import { Typeahead } from "react-bootstrap-typeahead";
import { Button, Image, Modal } from "react-bootstrap";

const Signup = () => {
  const clientHeight = window.innerHeight;

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const [golfers, setGolfers] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [golfer1, setGolfer1] = useState(null);
  const [golfer2, setGolfer2] = useState(null);
  const [golfer3, setGolfer3] = useState(null);
  const [golfer4, setGolfer4] = useState(null);
  const [golfer5, setGolfer5] = useState(null);

  const [disabled, setDisabled] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  const getGolfers = useCallback(() => {
    getAllGolfers().then((data) => {
      let simplifiedGolfers = [];
      data.forEach((g) => {
        let golfer = {
          playerId: g.playerId,
          label: g.firstName + " " + g.lastName,
        };

        simplifiedGolfers.push(golfer);
      });

      setGolfers(simplifiedGolfers);
    });
  }, []);

  useEffect(() => {
    getGolfers();
  }, [getGolfers]);

  useEffect(() => {
    if (
      firstName !== "" &&
      lastName !== "" &&
      golfer1 != null &&
      golfer2 != null &&
      golfer3 != null &&
      golfer4 != null &&
      golfer5 != null
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [firstName, lastName, golfer1, golfer2, golfer3, golfer4, golfer5]);

  const onSubmit = () => {
    if (
      !(
        golfer1[0]?.playerId &&
        golfer2[0]?.playerId &&
        golfer3[0]?.playerId &&
        golfer4[0]?.playerId &&
        golfer5[0]?.playerId
      )
    ) {
      setShowErrorModal(true);
    } else {
      setUpUser(
        firstName,
        lastName,
        golfer1[0]?.playerId,
        golfer2[0]?.playerId,
        golfer3[0]?.playerId,
        golfer4[0]?.playerId,
        golfer5[0]?.playerId
      )
        .then(() => {
          setShowConfirmationModal(true);
          setDisabled(true);
          setSubmitted(true);
        })
        .catch(() => {
          setShowErrorModal(true);
        });
    }
  };

  return (
    <>
      <Modal show={showConfirmationModal}>
        <Modal.Header>
          <Modal.Title>Picks submitted!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          The Pool page will appear once the tournament starts!
        </Modal.Body>
      </Modal>
      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>An error occured</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please try again or let us know that you are having issues.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="signup" style={{ height: clientHeight }}>
        {!submitted && (
          <>
            <div className="welcome-text">
              Welcome to our 2025 Masters pool!
            </div>

            <div className="fields">
              <div className="field">
                <div className="field__title">FIRST NAME</div>
                <div className="field__input">
                  <Form.Control
                    type="text"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="field">
                <div className="field__title">LAST NAME</div>
                <div className="field__input">
                  <Form.Control
                    type="text"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="field">
                <div>Golfer 1</div>

                <div className="field__select">
                  <Typeahead
                    id="golfer1"
                    options={golfers}
                    onChange={(g) => setGolfer1(g)}
                    selected={golfer1}
                    flip
                  />
                </div>
              </div>
              <div className="field">
                <div>Golfer 2</div>
                <div className="field__select">
                  <Typeahead
                    id="golfer2"
                    options={golfers}
                    onChange={(g) => setGolfer2(g)}
                    selected={golfer2}
                    flip
                  />
                </div>
              </div>
              <div className="field">
                <div>Golfer 3</div>
                <div className="field__select">
                  <Typeahead
                    id="golfer3"
                    options={golfers}
                    onChange={(g) => setGolfer3(g)}
                    selected={golfer3}
                    flip
                  />
                </div>
              </div>
              <div className="field">
                <div>Golfer 4</div>
                <div className="field__select">
                  <Typeahead
                    id="golfer4"
                    options={golfers}
                    onChange={(g) => setGolfer4(g)}
                    selected={golfer4}
                    flip
                  />
                </div>
              </div>
              <div className="field">
                <div>Golfer 5</div>
                <div className="field__select">
                  <Typeahead
                    id="golfer5"
                    options={golfers}
                    onChange={(g) => setGolfer5(g)}
                    selected={golfer5}
                    flip
                  />
                </div>
              </div>
              <Button disabled={disabled} onClick={onSubmit}>
                Submit
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Signup;
