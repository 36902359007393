import { ajax } from "jquery";

const tournId = "020";
const year = "2025";

export const retrieveGolfData = () => {
  return new Promise((resolve, reject) =>
    ajax({
      url: process.env.REACT_APP_API_URL + "/golf/1/getAllData",
      data: {
        tournId,
        year,
      },
      dataType: "json",
      type: "POST",
    })
      .done((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(null);
      })
  );
};

export const getAllGolfers = () => {
  return new Promise((resolve, reject) =>
    ajax({
      url: process.env.REACT_APP_API_URL + "/golf/1/getAllGolfers",
      data: {
        tournId,
        year,
      },
      dataType: "json",
      type: "POST",
    })
      .done((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(null);
      })
  );
};

export const setUpUser = (
  firstName,
  lastName,
  golfer1,
  golfer2,
  golfer3,
  golfer4,
  golfer5
) => {
  return new Promise((resolve, reject) =>
    ajax({
      url: process.env.REACT_APP_API_URL + "/golf/1/setUpUser",
      data: {
        firstName,
        lastName,
        golfer1,
        golfer2,
        golfer3,
        golfer4,
        golfer5,
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      method: "POST",
    })
      .done(() => {
        resolve();
      })
      .catch((error) => {
        console.log(error);
        reject();
      })
  );
};
